<style>
.add {
  @apply px-4 flex items-center font-medium mt-2 cursor-pointer;
  color: var(--primary);
  font-size: 17px;
  letter-spacing: -0.41px;
}
.add:hover {
  color: #479df7;
}
.add-icon {
  @apply mr-1;
}
.delete {
  @apply px-4 flex items-center mt-2 cursor-pointer;
  color: #c4272a;
}
.error {
  @apply px-4 mt-1 text-xs leading-none;
  color: #c4272a;
}
h2 {
  @apply leading-tight tracking-normal text-2xl font-semibold text-center;
}
</style>

<script>
import Select from "./_Select.svelte"
import { workflowLang } from "@shared/store/workflowKey"
import { addMoreText } from "@shared/utils/translations"
import SubHeading from "./SubHeading.svelte"

export let name = undefined
export let value = undefined
export let dispensaryOptions = []
export let quantityOptions = []
export let dispensaryRequired = ""
export let className = ""
export { _errors as errors }
export let label = ""

$: errors = _errors || []
$: values, onChange()

let _errors
let values = value || [{}]

function addValue() {
  if (validate()) {
    values.push({})
    values = values
  }
}

function validate() {
  let item = values.slice(-1)[0]
  if (!item.quantity || !item.dispensary) {
    errors.push("cannot be blank")
    errors = errors
    return false
  }
  errors = []
  return true
}

function removeValue(i) {
  values.splice(i, 1)
  values = values
  errors = []
}

function onChange() {
  value = values.filter((x) => !!x)

  if (value.length === 1 && !value[0].dispensary) {
    errors = []
    return
  }

  if (dispensaryRequired) {
    value.forEach((element, i) => {
      value[i].dispensaryRequired = element.dispensary === dispensaryRequired
    })

    if (!checkDispensaryRequired()) {
      errors.push(`${dispensaryRequired} must be include`)
      errors = errors
      return
    }
  }

  if (value.length > 1) {
    if (checkDuplicated()) {
      errors.push("duplicated")
      errors = errors
      return
    }
  }

  errors = []
}

function checkDuplicated() {
  const uniqueValues = new Set(value.map((v) => v.dispensary))
  return uniqueValues.size < values.length
}

function checkDispensaryRequired() {
  return value.some((x) => x.dispensary === dispensaryRequired)
}
</script>

<div id="{name}" class="w-full {className}">
  <SubHeading className="mb-8" content="{label}" />
  {#each values as value, i}
    <div
      class:mb-6="{i < values.length - 1}"
      class:mb-4="{i === values.length - 1 && errors.length == 0}"
      class="flex flex-row gap-2"
    >
      <Select
        name="{`${name}-lp-${i}`}"
        label="LP"
        options="{dispensaryOptions}"
        bind:value="{value.dispensary}"
        hasErrors="{errors.length > 0}"
        includeEmpty="{true}"
      />

      <Select
        name="{`${name}-quantity-${i}`}"
        label="Quantity"
        options="{quantityOptions}"
        bind:value="{value.quantity}"
        hasErrors="{errors.length > 0}"
        includeEmpty="{true}"
      />

      {#if values.length > 1}
        <div class="delete">
          <i
            class="material-icons"
            on:click="{() => removeValue(i)}"
          >remove_circle</i>
        </div>
      {/if}
    </div>
    {#if i === values.length - 1}
      {#if errors.length > 0}
        <div class="error">{errors[0]}</div>
      {/if}
    {/if}
  {/each}

  <div class="flex flex-row gap-2">
    <div class="add" on:click="{addValue}">
      <i class="add-icon material-icons">add_circle</i>
      {addMoreText[$workflowLang]}
    </div>
  </div>
</div>
