<style>
.row {
  @apply w-full max-w-screen-xl;
  @apply px-4 mx-auto;
}

.wrapper {
  @apply w-full flex flex-col flex-1 pb-24 bg-white bg-fixed bg-repeat;
  background: var(--bg-funnel-path);
}

.hero {
  background-color: var(--primary-lighter);
}

.card {
  @apply bg-white;
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.1);
}

.link {
  color: var(--primary);
}
</style>

<script>
import isPast from "date-fns/isPast"
import { link, navigate } from "svelte-routing"
import queryString from "query-string"

import { getProps } from "@local/config/datasource"
import { currentUser } from "@local/store/auth"
import { partner } from "@local/store/theme"

import FullLayout from "@shared/components/FullLayout.svelte"
import TemplateString from "@shared/components/TemplateString.svelte"
import Button from "@local/components/Button.svelte"
import { workflowLang } from "@shared/store/workflowKey"
import {
  morningText,
  howCanWeHelpYouTodayText,
  scheduleAProductDosingGuidanceConsultationText,
  renewYourMedicalDocumentText,
  yourPatientPortalText,
  downloadYourProductEDosingGuidanceText,
  downloadCopyOfPractitionerNotesText,
  downloadCopyOfPractitionerNotesOrMedicalDocumentText,
  continueWithYourRenewalProcessText,
  continueWithYourEducatorProcessText,
  continueWithYourSurveyProcessText,
  allOtherInquiresText,
  deniedText,
  talkWithOurSupportTeamText,
  onText,
  expiredText,
  expiresText,
  viewText,
  startSurveyText,
} from "@shared/utils/translations"

export let location = {}
let queryParams = queryString.parse(location.search)

let promise = (async function () {
  const props = await getProps()

  const educatorWfKey = $partner.origin.educatorWfKey
  const surveyWfKey = $partner.origin.surveyWfKey
  const ongoingWfs = props.ongoingWfs
  const completedWfs = props.completedWfs
  const isReturnAllowed =
    isPast(new Date(props.earliestReturnAt)) &&
    ongoingWfs.filter((wfs) => wfs.type === "medical").length === 0
  const isEducatorReturnAllowed =
    (!props.earliestEducatorReturnAt ||
      isPast(new Date(props.earliestEducatorReturnAt))) &&
    ongoingWfs.filter((wfs) => wfs.type === "educator").length === 0
  const isSurveyReturnAllowed =
    surveyWfKey &&
    $currentUser.rootId &&
    ongoingWfs.filter((wfs) => wfs.type === "survey").length === 0

  const { utm_source: referralUtmSource, utm_campaign: referralUtmCampaign } =
    queryParams

  const query = new URLSearchParams()
  if (referralUtmSource) query.set("utm_source", referralUtmSource)
  if (referralUtmCampaign) query.set("utm_campaign", referralUtmCampaign)

  const queryStart = query.toString() ? `/start?${query.toString()}` : "/start"

  if (!props.workflowId) {
    if ($currentUser.roles.includes("patient")) {
      navigate(queryStart, { replace: true })
    } else {
      navigate("/doctor-workstation", { replace: true })
    }
  }
  return [
    props,
    isReturnAllowed,
    isEducatorReturnAllowed,
    isSurveyReturnAllowed,
    ongoingWfs,
    completedWfs,
    educatorWfKey,
    surveyWfKey,
  ]
})()
</script>

{#await promise then [props, isReturnAllowed, isEducatorReturnAllowed, isSurveyReturnAllowed, ongoingWfs, completedWfs, educatorWfKey, surveyWfKey]}
  <FullLayout>
    <div class="wrapper">
      <div class="hero">
        <div class="row">
          <div class="flex flex-col items-center justify-center py-16 center">
            <h2 class="mb-12 text-center type-header">
              {morningText[$workflowLang]}
              {props.firstName}.
              {howCanWeHelpYouTodayText[$workflowLang]}
            </h2>
            <ul class="flex flex-col w-full md:flex-row md:flex-end mb-4">
              {#if educatorWfKey && isEducatorReturnAllowed && !isReturnAllowed}
                <li
                  class="flex flex-col justify-center flex-1 mx-8 mb-8 md:mb-0"
                >
                  <Button
                    type="button"
                    color="primary"
                    filled="{false}"
                    label="{scheduleAProductDosingGuidanceConsultationText[
                      $workflowLang
                    ]}"
                    on:click="{() =>
                      navigate(`/workflows/by-key/${educatorWfKey}`)}"
                  />
                </li>
              {/if}
            </ul>
            <ul class="flex flex-col w-full md:flex-row md:flex-end mb-4">
              {#if isReturnAllowed}
                <li
                  class="flex flex-col justify-center flex-1 mx-8 mb-8 md:mb-0"
                >
                  <Button
                    type="button"
                    color="primary"
                    filled="{true}"
                    label="{renewYourMedicalDocumentText[$workflowLang]}"
                    on:click="{() =>
                      navigate(`/workflows/by-key/${props.returnWfKey}`)}"
                  />
                </li>
              {/if}
            </ul>
            <ul class="flex flex-col w-full md:flex-row md:flex-end">
              {#if isSurveyReturnAllowed}
                <li
                  class="flex flex-col justify-center flex-1 mx-8 mb-8 md:mb-0"
                >
                  <Button
                    type="button"
                    color="primary"
                    filled="{true}"
                    label="{startSurveyText[$workflowLang]}"
                    on:click="{() =>
                      navigate(`/workflows/by-key/${surveyWfKey}`)}"
                  />
                </li>
              {/if}
            </ul>
          </div>
        </div>
      </div>

      <div class="row">
        <h3 class="mt-16 mb-8 text-left type-sub-header">
          {yourPatientPortalText[$workflowLang]}
        </h3>
        <ul class="grid grid-cols-1 gap-4 md:grid-cols-3">
          {#each ongoingWfs as ongoingWf}
            {#if ongoingWf.type === "medical"}
              <li class="flex items-center justify-between p-6 rounded-lg card">
                <div>
                  <h4 class="type-paragraph">
                    {continueWithYourRenewalProcessText[$workflowLang]}
                  </h4>
                </div>
                <a
                  class="font-semibold link hover:opacity-75"
                  use:link
                  href="{`/workflows/${ongoingWf.id}`}">Go</a
                >
              </li>
            {/if}
          {/each}
          {#each ongoingWfs as ongoingWf}
            {#if ongoingWf.type === "educator"}
              <li class="flex items-center justify-between p-6 rounded-lg card">
                <div>
                  <h4 class="type-paragraph">
                    {continueWithYourEducatorProcessText[$workflowLang]}
                  </h4>
                </div>
                <a
                  class="font-semibold link hover:opacity-75"
                  use:link
                  href="{`/workflows/${ongoingWf.id}`}">Go</a
                >
              </li>
            {/if}
            {#if ongoingWf.type === "survey"}
              <li class="flex items-center justify-between p-6 rounded-lg card">
                <div>
                  <h4 class="type-paragraph">
                    {continueWithYourSurveyProcessText[$workflowLang]}
                  </h4>
                </div>
                <a
                  class="font-semibold link hover:opacity-75"
                  use:link
                  href="{`/workflows/${ongoingWf.id}`}">Go</a
                >
              </li>
            {/if}
          {/each}
          {#each completedWfs as completedWf}
            {#if completedWf.type === "medical"}
              <li class="flex items-center justify-between p-6 rounded-lg card">
                <div>
                  <h4 class="type-paragraph">
                    {#if $partner.name === "HelloMD"}
                      {downloadCopyOfPractitionerNotesText[$workflowLang]}
                    {:else}
                      {downloadCopyOfPractitionerNotesOrMedicalDocumentText[
                        $workflowLang
                      ]}
                    {/if}
                  </h4>
                  {#if completedWf.doesDoctorRecommend}
                    <span class="text-xs"
                      >{completedWf.doesDoctorRecommend &&
                      isPast(new Date(completedWf.expiresAt))
                        ? expiredText[$workflowLang]
                        : expiresText[$workflowLang]}
                      {onText[$workflowLang]}
                      <TemplateString
                        content="{`{{${completedWf.expiresAt}||formatDate}}`}"
                      />
                    </span>
                  {:else if completedWf.connectedAt}
                    <span class="text-xs"
                      >{deniedText[$workflowLang]}&nbsp;{onText[$workflowLang]}
                      <TemplateString
                        content="{`{{${completedWf.connectedAt}||formatDate}}`}"
                      />
                    </span>
                  {:else}
                    <span class="text-xs">{deniedText[$workflowLang]}</span>
                  {/if}
                </div>
                <a
                  class="font-semibold link hover:opacity-75"
                  use:link
                  href="{`/workflows/${completedWf.id}`}"
                  >{viewText[$workflowLang]}</a
                >
              </li>
            {:else if completedWf.type === "educator"}
              <li class="flex items-center justify-between p-6 rounded-lg card">
                <div>
                  <h4 class="type-paragraph">
                    {downloadYourProductEDosingGuidanceText[$workflowLang]}
                  </h4>
                  <span class="text-xs">
                    <TemplateString
                      content="{`{{${
                        completedWf.educatorPublishedAt || new Date()
                      }||formatDate}}`}"
                    />
                  </span>
                </div>
                <a
                  class="font-semibold link hover:opacity-75"
                  use:link
                  href="{`/workflows/${completedWf.id}`}"
                  >{viewText[$workflowLang]}</a
                >
              </li>
            {/if}
          {/each}
        </ul>
      </div>
      <div class="row">
        <h3 class="mt-16 mb-8 text-left type-sub-header">
          {allOtherInquiresText[$workflowLang]}
        </h3>
        <Button
          type="button"
          color="primary"
          filled="{true}"
          label="{talkWithOurSupportTeamText[$workflowLang]}"
          on:click="{() => window.Intercom && window.Intercom('show')}"
        />
      </div>
    </div>
  </FullLayout>
{/await}
