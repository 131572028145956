<style>
.textarea-wrapper {
  @apply w-full border-0 border-b-4 pt-6 rounded relative;
  background-color: #f3f3f3;
  border-color: #757575;
}
.textarea-wrapper.focused {
  border-color: var(--primary);
}
.textarea-wrapper.has-errors {
  border-color: #c4272a;
}
.textarea {
  @apply block w-full bg-transparent border-0 outline-none text-black text-lg px-4 pb-1 ease-in duration-200 appearance-none;
  line-height: 1.5rem;
  letter-spacing: -0.43px;
}
.textarea.growable {
  @apply pr-10;
}
.textarea.unresizable {
  @apply resize-none;
}
.label {
  @apply w-full text-lg pt-4 pb-4 px-4 ease-in duration-200 absolute top-0 truncate rounded-t;
  color: #9b9b9b;
  background-color: #f3f3f3;
  line-height: 1.25rem;
}
.label.expanded {
  @apply pb-1 pt-2 text-xs leading-none;
}
.label.focused {
  @apply pointer-events-none;
  color: var(--primary);
}
.icon {
  @apply flex justify-center items-center absolute top-0 right-0 py-4 px-4 text-lg cursor-pointer;
  font-size: 24px;
}
.icon:hover {
  color: #757575;
}
</style>

<script>
export let name = ""
export let label = ""
export let value = undefined
export let hasErrors = false

export let growable = false
export let resizable = false
export let rows = 3

let focused = false
let grown = false

$: expanded = value || focused
$: error = !focused && hasErrors
$: rows = grown ? rows + 5 : rows
</script>

<div class="textarea-wrapper" class:focused class:has-errors="{hasErrors}">
  {#if label}
    <label for="{name}" class="label" class:focused class:expanded>
      {label}
    </label>
  {/if}
  <textarea
    class="textarea"
    class:growable
    class:unresizable="{!resizable}"
    name="{name}"
    id="{name}"
    on:focus="{() => (focused = true)}"
    on:blur="{() => (focused = false)}"
    bind:value
    rows="{rows}"
  ></textarea>
  {#if growable}
    <i class="icon material-icons" on:click="{() => (grown = !grown)}">
      open_in_full
    </i>
  {/if}
</div>
