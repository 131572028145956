<style>
.background {
  @apply relative flex flex-col min-h-screen;
  background: var(--bg-pattern-path) repeat, var(--bg-color);
  background-size: 993px 1516px;
}

.bg-funnel {
  @apply w-full bg-fixed bg-repeat;
  background: var(--bg-funnel-path);
}

.bar {
  background-color: var(--bar-color);
}
</style>

<script>
import { link } from "svelte-routing"

import { isLoggedIn } from "../../store/auth"

import Menu from "./Menu.svelte"

import {
  logoAlt,
  logoPath,
  topBar,
  leftTopBarMessage,
  rightTopBarMessage,
  languages,
  partner,
  hideLanguageSelector,
} from "../../store/theme"
import { loginText } from "@shared/utils/translations"
import { setWorkflowLang, workflowLang } from "@shared/store/workflowKey"

const langText = {
  en: "English",
  fr: "Français",
}
const langTextMobile = {
  en: "En",
  fr: "Fr",
}
const logoUrl = $partner.origin.props["homepageUrl"]
  ? $partner.origin.props["homepageUrl"]
  : "/"
</script>

<div class="flex flex-col min-h-screen background">
  {#if $topBar}
    <div class="text-xs md:text-sm leading-4 md:leading-none text-white bar">
      <div
        class="flex flex-row items-center w-full h-10 max-w-screen-xl px-2 md:px-6 mx-auto justify-between justify-items-center"
      >
        <div class="w-28 md:w-64">{$leftTopBarMessage}</div>
        <div
          class="flex flex-row md:justify-end justify-center items-center w-full h-8 px-0 md:px-6"
        >
          <div
            class="text-right whitespace-pre-wrap md:whitespace-normal text-xs md:text-sm"
          >
            {$rightTopBarMessage}
          </div>
          {#if !$hideLanguageSelector}
            <div class="ml-2 md:ml-4">
              {#each $languages as lang}
                {#if $workflowLang !== lang}
                  <span
                    class="hidden md:block cursor-pointer hover:font-semibold hover:underline"
                    on:click="{() => setWorkflowLang(lang)}"
                  >
                    {langText[lang]}
                  </span>
                  <span
                    class="block md:hidden p-1 text-sm cursor-pointer hover:font-semibold hover:underline"
                    on:click="{() => setWorkflowLang(lang)}"
                  >
                    {langTextMobile[lang]}
                  </span>
                {/if}
              {/each}
            </div>
          {/if}
        </div>
      </div>
    </div>
  {/if}
  <div
    class="relative z-50 flex items-center w-full bg-white border-b border-gray-400 rounded rounded-b-none shadow-md h-header"
  >
    <div
      class="flex justify-between w-full h-full max-w-screen-xl px-4 mx-auto flex-row"
    >
      <div class="flex flex-row items-center w-28 md:w-1/2 h-full">
        <a
          href="{logoUrl}"
          class="
                  block
                  visited:text-white 
                  hover:no-underline
                  h-12
                  bg-no-repeat
                  bg-contain"
          style="width: 18rem; background-image: url('{$logoPath}')"
          use:link
        >
          <span class="invisible">{$logoAlt}</span>
        </a>
      </div>
      {#if $isLoggedIn}
        <Menu />
      {:else}
        <div class="flex items-center justify-center">
          <a href="/login?next=/get-started" use:link class="inline">
            {loginText[$workflowLang]}
          </a>
        </div>
      {/if}
    </div>
  </div>
  <div class="slot bg-funnel" class:topBar="{$topBar}">
    <slot />
  </div>
</div>
