import { referral as _referral } from "../store/referral"
import {
  workflowKey as _workflowKey,
  workflowLang as _workflowLang,
} from "@shared/store/workflowKey"
import { getAccessToken, currentUser } from "../store/auth"
import qs from "query-string"

import { apiBasePath, patientRole, doctorRole, educatorRole, assistantRole } from "./constants"

let referralCode

_referral.subscribe(function (referral) {
  referralCode = referral ? referral.code : undefined
})

let workflowKey

_workflowKey.subscribe(function (value) {
  workflowKey = value
})

let workflowLang

_workflowLang.subscribe(function (value) {
  workflowLang = value
})

const baseHeaders = { "Content-Type": "application/json" }

async function getHeaders() {
  const accessToken = await getAccessToken()
  return { ...baseHeaders, Authorization: `bearer ${accessToken}` }
}

export async function postPatientWorkflow(workflowKey, referralUtms) {
  const role = patientRole
  const lang = workflowLang
  const { referralUtmSource, referralUtmCampaign } = referralUtms
  const headers = await getHeaders()
  const response = await fetch(
    `${apiBasePath}/workflows/def-key/${workflowKey}?${qs.stringify({
      role,
      referralCode,
      lang,
      referralUtmSource,
      referralUtmCampaign
    })}`,
    { method: "POST", headers }
  )
  const data = await response.json()
  return data
}

export async function backPatientWorkflow(wfId, steps) {
  const role = patientRole
  const lang = workflowLang
  const headers = await getHeaders()
  const direction = "backward"
  const response = await fetch(
    `${apiBasePath}/workflows/${wfId}?${qs.stringify({
      role,
      direction,
      lang,
    })}`,
    {
      method: "PATCH",
      headers,
      body: JSON.stringify({ steps }),
    }
  )
  if (response.status === 422) {
    const errors = await response.json()
    throw errors
    return
  }
  const data = await response.json()
  const elements = data.elements
  const payload = data.payload
  return { elements, payload }
}

export async function getPatientWorkflowId(workflowKey, referralUtms) {
  const role = patientRole
  const lang = workflowLang
  const headers = await getHeaders()
  const response = await fetch(
    `${apiBasePath}/workflows/def-key/${workflowKey}?${qs.stringify({
      role,
      lang,
    })}`,
    { headers }
  )
  if (response.status === 404) {
    return await postPatientWorkflow(workflowKey, referralUtms)
  }
  const data = await response.json()
  return data
}

export async function getListByDefKey({
  defKey,
  page = 0,
  perPage = 20,
  query,
  roleOverwrite,
  ...filters
}) {
  const role = getCurrentUser().roles.find(
    (role) => role === doctorRole || role === educatorRole
  )
  const lang = workflowLang
  const headers = await getHeaders()
  const queryParams = { role, page, perPage, lang, ...filters }

  if (query) queryParams.query = query
  if (roleOverwrite) queryParams.role = getCurrentUser().roles.find(
    (role) => role === assistantRole
  )
  const queryStr = qs.stringify(queryParams)
  const response = await fetch(
    `${apiBasePath}/lists/def-key/${defKey}?${queryStr}`,
    { headers }
  )

  let meta = {}
  meta.status = response.status
  if (response.headers.has("X-Total-Count"))
    meta.total = parseInt(response.headers.get("X-Total-Count"))

  const data = meta.status === 204 ? null : await response.json()

  return [data, meta]
}

export async function getPatientWorkflowById(workflowId) {
  const role = patientRole
  const lang = workflowLang
  const headers = await getHeaders()
  const response = await fetch(
    `${apiBasePath}/workflows/${workflowId}?${qs.stringify({
      role,
      referralCode,
      lang,
    })}`,
    { headers }
  )
  const data = await response.json()
  const elements = data.elements
  const payload = data.payload
  const prettyUrl = data.prettyUrl
  return { elements, payload, prettyUrl }
}

export async function patchPatientWorkflowById(workflowId, body = {}) {
  const role = patientRole
  const lang = workflowLang
  const headers = await getHeaders()
  const response = await fetch(
    `${apiBasePath}/workflows/${workflowId}?${qs.stringify({
      role,
      referralCode,
      lang,
    })}`,
    {
      method: "PATCH",
      headers,
      body: JSON.stringify(body),
    }
  )
  if (response.status === 422) {
    const errors = await response.json()
    throw errors
  }
  const data = await response.json()
  const elements = data.elements
  const payload = data.payload
  const prettyUrl = data.prettyUrl
  return { elements, payload, prettyUrl }
}

export async function patchDoctorOrEducatorWorkflow(workflowId, body = {}) {
  const role = getCurrentUser().roles.find(
    (role) => role === doctorRole || role === educatorRole
  )
  const lang = workflowLang
  const headers = await getHeaders()
  const response = await fetch(
    `${apiBasePath}/workflows/${workflowId}?${qs.stringify({ role, lang })}`,
    {
      method: "PATCH",
      headers,
      body: JSON.stringify(body),
    }
  )
  if (response.status === 422) {
    const errors = await response.json()
    throw errors
  }
  const data = await response.json()
  const elements = data.elements
  const payload = data.payload
  return { elements, payload }
}

export async function getDoctorOrEducatorWorkflow(workflowId, roleOverwrite) {
  const role = roleOverwrite || getCurrentUser().roles.find(
    (role) => role === doctorRole || role === educatorRole
  )
  const lang = workflowLang
  const headers = await getHeaders()
  const response = await fetch(
    `${apiBasePath}/workflows/${workflowId}?${qs.stringify({ role, lang })}`,
    { headers }
  )
  const data = await response.json()
  const elements = data.elements
  const payload = data.payload
  return { elements, payload }
}

export async function getSubdomain(subdomain) {
  const lang = workflowLang
  const response = await fetch(
    `${apiBasePath}/partners/subdomain/${subdomain}?${qs.stringify({ lang })}`
  )
  return await response.json()
}

export async function postUser({ email, password, partnerId, next }) {
  const headers = baseHeaders
  const response = await fetch(
    `${apiBasePath}/partners/${partnerId}/users?next=${next}`,
    {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers,
    }
  )
  return await response.json()
}

export async function patchLanguage({ partnerId, userId, language }) {
  const headers = await getHeaders()
  const response = await fetch(
    `${apiBasePath}/partners/${partnerId}/user/${userId}/language`,
    { method: "PATCH", headers, body: JSON.stringify({ language }) }
  )
  const data = await response.json()
  return data
}

export async function postToken({ email, password, partnerId }) {
  const headers = baseHeaders
  const response = await fetch(`${apiBasePath}/partners/${partnerId}/tokens`, {
    method: "POST",
    body: JSON.stringify({ email, password }),
    headers,
  })
  return await response.json()
}

export async function postPasswordResetEmails({
  email,
  partnerId,
  partnerOrigin,
  next,
  lang,
}) {
  const response = await fetch(
    `${apiBasePath}/partners/${partnerId}/user/password-reset-emails?next=${next}`,
    {
      method: "POST",
      body: JSON.stringify({ email, partnerOrigin, lang }),
      headers: baseHeaders,
    }
  )
  if (response.status === 422) {
    const errors = await response.json()
    throw errors
  }
  return
}

export async function patchPassword({ token, partnerId, password }) {
  const response = await fetch(
    `${apiBasePath}/partners/${partnerId}/user/password`,
    {
      method: "PATCH",
      body: JSON.stringify({ token, password }),
      headers: baseHeaders,
    }
  )
  if (response.status === 422) throw { response }

  return await response.json()
}

export async function postConfirmationEmails({
  email,
  partnerId,
  partnerOrigin,
  next,
  referralUtms
}) {
  const { referralUtmSource, referralUtmCampaign } = referralUtms

  const query = new URLSearchParams()
  if (next) query.set('next', next)
  if (referralUtmSource) query.set('referralUtmSource', referralUtmSource)
  if (referralUtmCampaign) query.set('referralUtmCampaign', referralUtmCampaign)
  if (referralCode) query.set('referralCode', referralCode)

  await fetch(
    `${apiBasePath}/partners/${partnerId}/user/confirmation-emails${query.toString() ? `?${query.toString()}` : ""}`,
    {
      method: "POST",
      body: JSON.stringify({ email, partnerOrigin }),
      headers: baseHeaders,
    }
  )
  return
}

export async function patchIsEmailConfirmed({ token, partnerId }) {
  const headers = baseHeaders
  const response = await fetch(
    `${apiBasePath}/partners/${partnerId}/user/is-email-confirmed`,
    {
      method: "PATCH",
      body: JSON.stringify({ token }),
      headers,
    }
  )
  return await response.json()
}

export async function getProps() {
  const role = patientRole
  const headers = await getHeaders()
  const response = await fetch(
    `${apiBasePath}/props?${qs.stringify({ role })}`,
    { headers }
  )
  return await response.json()
}

export async function getMedicines() {
  const role = patientRole
  const headers = await getHeaders()
  const response = await fetch(
    `${apiBasePath}/medicines?${qs.stringify({ role })}`,
    { headers }
  )
  return await response.json()
}

export async function getView({workflowId, viewKey, role}) {
  const headers = await getHeaders()
  const response = await fetch(`${apiBasePath}/workflows/${workflowId}/views/${viewKey}?${qs.stringify({ role })}`,
    { headers }
  )
  return await response.json()
}

export async function patchView({workflowId, viewKey, payload, role}) {
  const headers = await getHeaders()
  await fetch(`${apiBasePath}/workflows/${workflowId}/views/${viewKey}?${qs.stringify({ role })}`, 
    {
      method: "PATCH",
      headers,
      body: JSON.stringify(payload),
    }
  )
}

function getCurrentUser() {
  let _currentUser
  currentUser.subscribe(function (value) {
    _currentUser = value
  })
  return _currentUser
}
